
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                return _createElement('div', { 'className': 'product-grid-item mode-view-item' }, _createElement('div', { 'className': 'product-wrapper effect-switch' }, _createElement('div', { 'className': 'product-inner-wrapper' }, _createElement('div', { 'className': 'product-head' }, _createElement('div', { 'className': 'product-button' }, _createElement('div', {
                    'data-target': '#quick-shop-popup',
                    'data-handle': this.handle,
                    'className': 'quick_shop',
                    'data-toggle': 'modal',
                    'title': 'Quick View'
                }, _createElement('i', { 'className': 'demo-icon icon-eye-1' }))), _createElement('div', { 'className': 'product-image' }, _createElement('div', { 'className': 'featured-img' + (this.image2 ? ' switch-image' : '') }, _createElement('a', { 'href': this.url }, _createElement('img', {
                    'className': 'featured-image front img-lazy blur-up lazyautosizes lazyloaded',
                    'alt': this.removeHTML(this.title),
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'onError': this.onImageError
                }), this.image2 ? _createElement('span', {
                    'className': 'img-back d-none d-lg-block',
                    'key': '809'
                }, _createElement('img', {
                    'className': 'back blur-up lazyautosizes lazyloaded',
                    'alt': this.removeHTML(this.title),
                    'src': this.resizeImage(this.image2),
                    'onError': e => this.onImageError(e, 'image2')
                })) : null)))), _createElement('span', { 'className': 'product-label notranslate' }, !this.out_of_stock && this.on_sale ? _createElement('span', {
                    'className': 'label-sale',
                    'key': '1214'
                }, _createElement('span', { 'className': 'sale-text' }, 'Sale')) : null), _createElement('div', { 'className': 'product-content text-left' }, _createElement('div', { 'className': 'pc-inner' }, _createElement('div', { 'className': 'product-group-vendor-name' }, _createElement('div', { 'className': 'product-vendor' }, _createElement('a', Object.assign({}, {
                    'href': this.vendor_url,
                    'title': this.removeHTML(this.vendor)
                }, { dangerouslySetInnerHTML: { __html: this.vendor } }))), _createElement('h5', {
                    'className': 'product-name',
                    'style': { height: '60px' }
                }, _createElement('a', Object.assign({}, { 'href': this.url }, { dangerouslySetInnerHTML: { __html: this.breakWordOnSlash(this.title) } })))), _createElement('div', { 'className': 'product-sku' }, _createElement('span', {}, 'SKU: ', this.sku ? this.removeHTML(this.sku) : 'N/A')), _createElement('div', { 'className': 'price-cart-wrapper' }, _createElement('div', { 'className': 'product-price' }, !this.on_sale ? [_createElement('span', {
                        'className': 'price',
                        'key': '21491'
                    }, _createElement('span', { 'className': 'money' }, this.formatPrice(this.price)))] : null, !this.out_of_stock && this.on_sale ? [
                    _createElement('span', {
                        'className': 'price-sale',
                        'key': '23641'
                    }, _createElement('span', { 'className': 'money' }, this.formatPrice(this.price))),
                    _createElement('span', {
                        'className': 'price-compare',
                        'key': '23643'
                    }, _createElement('span', { 'className': 'money' }, this.formatPrice(this.compare_at_price)))
                ] : null, this.out_of_stock ? _createElement('span', {
                    'className': 'sold-out',
                    'key': '2768'
                }, 'Sold Out') : null), !this.out_of_stock ? _createElement('div', {
                    'className': 'product-add-cart',
                    'key': '2864'
                }, _createElement('form', {
                    'action': '/cart/add',
                    'method': 'post',
                    'encType': 'multipart/form-data'
                }, _createElement('a', {
                    'className': 'btn-add-cart add-to-cart',
                    'title': 'Add to cart'
                }, _createElement('span', { 'className': 'demo-icon icon-chrom-online-shopping-cart' })), _createElement('select', {
                    'className': 'd-none',
                    'name': 'id'
                }, _createElement('option', { 'value': this.variant_ids }, 'Default Title')))) : null))))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products container' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also ', _createElement('span', {}, 'like...'))), _createElement.apply(this, [
        'div',
        { 'className': 'cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []