//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-735:_4540,_6052,_5140,_8200,_5224,_828,_1936,_9652;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-735')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-735', "_4540,_6052,_5140,_8200,_5224,_828,_1936,_9652");
        }
      }catch (ex) {
        console.error(ex);
      }