import { useCallback, useEffect, useMemo, useState } from 'react';

import history from 'Core/history.js';
import requestConfig from 'Models/uiConfig/requestConfig.js';
import { getRequestFromUri } from 'Modules/converter/index.js';
import { abortControllerCreator, search } from 'Modules/serverApi/index.js';

import type { Path } from 'history';
import type { FacetValueBase, FacetValueFull } from 'Models/index.ts';
import type { ServerModel } from 'Modules/serverApi/types.ts';

type NormRequest = Record<string, string | undefined>;

export type NormResponse = {
  field: string;
  title: string;
  entries: { value: string; term: string; selected: boolean }[];
}[];

type Params = {
  isLoading: boolean;
  response: NormResponse;
  clear: () => void;
  onChange: (field: string) => (value: string) => void;
};

export default function useAutonomousSearch(
  fields: string[],
  requestExtra?: Record<string, unknown>,
): Params {
  const createAbortController = useMemo(() => abortControllerCreator(), []);
  const [isLoading, setIsLoading] = useState(true);
  const [facets, setFacets] = useState<ServerModel.FacetCategory[]>();
  const [selection, setSelection] = useState<NormRequest | null>(null);
  const [currentLocation, setCurrentLocation] = useState<Path>(window.location);

  useEffect(
    function sendRequest() {
      if (!selection) {
        return;
      }
      search(
        {
          selection: [
            ...denormalizeRequest(selection),
            // feature flag for DiscountTireZone
            // { field: 'EnableYMM', term: 'True' },
          ],
          extra: { mode: 'RequestPanel', ...(requestExtra || {}) },
          pageSize: '0',
        },
        createAbortController(),
      ).then((rawResponse) => {
        if (rawResponse.State === 'cancelled') {
          return;
        }
        setIsLoading(false);
        setFacets(requestConfig.responseHandler(rawResponse).Facets);
      });
    },
    [createAbortController, selection],
  );

  useEffect(function subscribeToHistory() {
    return history.listen(({ location }) => setCurrentLocation(location));
  }, []);

  useEffect(
    function updateSelectionOnFieldsOrHistoryChange() {
      setSelection((prevSelection) => {
        const nextSelection = normalizeRequest(fields, getRequestFromUri(currentLocation).selection);
        return prevSelection && selectionsEqual(prevSelection, nextSelection) ? prevSelection : nextSelection;
      });
    },
    [currentLocation, fields],
  );

  const clear = useCallback(
    () => setSelection((selection) => (selection && Object.entries(selection).length ? {} : selection)),
    [],
  );

  const onChange = (field: string) => (value: string) =>
    selection?.[field] !== value && setSelection({ ...selection, [field]: value || undefined });

  return {
    isLoading,
    response: useMemo(() => normalizeResponse(fields, facets), [facets, fields]),
    clear,
    onChange,
  };
}

function normalizeRequest(fields: string[], selection: FacetValueFull[]): NormRequest {
  return Object.fromEntries(
    fields.map((field) => [field, selection.find((v) => v.field === field)?.term]).filter((pair) => pair[1]),
  );
}

function denormalizeRequest(selection: NormRequest): FacetValueBase[] {
  return Object.entries(selection)
    .map(([field, term]) => ({ field, term }))
    .filter((v) => v.term) as FacetValueBase[];
}

function normalizeResponse(fields: string[], facets: ServerModel.FacetCategory[] | undefined): NormResponse {
  return facets
    ? (fields
        .map((field) => {
          const facet = facets.find((facet) => facet.FieldName === field);
          return (
            facet && {
              field,
              title: facet.DisplayName,
              entries: facet.Values?.map((v) => ({
                value: v.Value,
                term: v.Term,
                selected: v.Selected ?? false,
              })),
            }
          );
        })
        .filter(Boolean) as NormResponse)
    : [];
}

function selectionsEqual(prevSelection: NormRequest, nextSelection: NormRequest): boolean {
  const prevFields = Object.keys(prevSelection);
  const nextFields = Object.keys(nextSelection);
  return (
    prevFields.length === nextFields.length &&
    [...prevFields, ...nextFields].every((field) => prevSelection[field] === nextSelection[field])
  );
}
