
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function vehicleLabelContainerRT () {
    return this.template === 'locked' ? _createElement('div', {
        'className': 'cm_vehicle-widget_label__container cmTemplate_locked',
        'key': '0'
    }, _createElement('span', { 'className': 'cm_vehicle-widget_vehicle-title' }, this.vehicleString), _createElement('span', { 'className': 'cm_brand-title' })) : null;
}
        export const componentNames = []