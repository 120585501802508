import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createSelectRepeater, SelectContextProvider } from 'Components/smallComponents/select.tsx';
import { RepeaterFunctionInvoker } from 'Components/types.ts';
import {
  discardField,
  discardManyFields,
  replaceValue,
  replaceManyValues,
  toggle,
} from 'Core/actions/request.js';
import { vehicleSelected } from 'Core/actions/fitmentSearch/index.js';
import { assignLocation } from 'Core/actions/redirect.ts';
import { useCollapsible } from 'Core/hooks/index.js';
import { createFilteredFacetsSelector } from 'Core/selectors/search.js';
import { resultsLoadingSelector } from 'Core/selectors/show.ts';
import {
  fitmentSearchFieldsSelector,
  fitmentSearchBaseFieldsSelector,
} from 'Core/selectors/fitmentSearch/index.js';
import useAutonomousSearchHook from 'Core/hooks/autonomousSearch.ts';
import { Vehicle } from 'Models/index.ts';
import fitmentSearchConfig from 'Models/uiConfig/fitmentSearchConfig.js';
import requestConfig from 'Models/uiConfig/requestConfig.js';
import uiConfig from 'Models/uiConfig/uiConfig.js';
import { getUriFromRequest } from 'Modules/converter/index.js';
import { cloneSafe } from 'Utils/components.ts';

import type { TemplateFunction } from 'Components/types.ts';
import type { NormResponse } from 'Core/hooks/autonomousSearch.ts';
import type { Facet, FacetRequest } from 'Models/index.ts';
import type { VehicleValue } from 'Models/vehicle.ts';

type Params = {
  collapsed: boolean;
  disableClear: boolean;
  inputs: RepeaterFunctionInvoker<InputsRepeater>;
  hasFullSelection: boolean;
  hasRequiredSelection: boolean;
  hasSelection: boolean;
  selection: Record<string, string>;
  selects: ReturnType<typeof createSelectRepeater>;
  clear: () => void;
  submit: () => void;
  toggleCollapsed: () => void;
};
type InputsRepeater = {
  facetInput: (template: TemplateFunction<HTMLInputElement>) => JSX.Element;
};

type Props = {
  template: TemplateFunction<Params>;
  initCollapsed?: boolean;
  inputFields?: string[];
  isAutoRedirectDisabled?: true;
  onSubmit?: () => void;
  requiredFields?: string[];
  redirectUrl?: string;
  requestExtra?: Record<string, unknown>;
  selectFields?: string[];
  shouldBeIsolated?: () => void;
  useNativeDropdown?: boolean;
};

export default function RequestPanel({
  template,
  isAutoRedirectDisabled,
  initCollapsed = false,
  inputFields,
  onSubmit,
  requiredFields,
  redirectUrl,
  requestExtra,
  selectFields,
  shouldBeIsolated,
  useNativeDropdown,
}: Props) {
  const dispatch = useDispatch();
  const rootRef = useRef<HTMLElement>(null);

  const [collapsed, toggleCollapsed] = useCollapsible(rootRef, null, initCollapsed);

  const [response, setResponse] = useState<NormResponse>([]);
  const [inputValues, setInputValues] = useState<Record<string, string>>({});

  const fields = useMemo(
    () => [...(selectFields || []), ...(inputFields || [])],
    [inputFields, selectFields],
  );

  const useAutonomousSearch = !requestConfig.hasSearchResults || !!shouldBeIsolated?.();

  const {
    isLoading: isAutonomousLoading,
    response: autonomousResponse,
    clear: autonomousClear,
    onChange: autonomousOnChange,
  } = useAutonomousSearchHook(fields, requestExtra);

  const isSearchLoading = useSelector(resultsLoadingSelector);
  const searchResponse = useSelector(useMemo(() => createFilteredFacetsSelector(fields), [fields]));

  const fitmentFields = useSelector(fitmentSearchFieldsSelector);
  const fitmentBaseFields = useSelector(fitmentSearchBaseFieldsSelector) as string[];

  const normSearchResponse = useMemo(
    () =>
      searchResponse.map((facet: Facet) => ({
        field: facet.field,
        title: facet.name,
        entries: facet.values?.map((value) => ({ ...value, selected: value.isSelected })),
      })),
    [searchResponse],
  ) as NormResponse;

  const isLoading = useAutonomousSearch ? isAutonomousLoading : isSearchLoading;

  const clear = () => {
    if (useAutonomousSearch) {
      autonomousClear();
    } else {
      dispatch(discardManyFields(fields));
    }

    setInputValues({});
  };

  const onValueSelected = (field: string, value: string, isSingleValue: boolean) =>
    useAutonomousSearch
      ? autonomousOnChange(field)(value)
      : isSingleValue
        ? dispatch(toggle({ field, term: value }, { isSingleValue: true, mayDiscardValue: true }))
        : dispatch(replaceValue({ field, term: value }, { mayDiscardValue: true }));

  const selection = Object.fromEntries(
    fields.map((field) => [
      field,
      response.find((facet) => facet.field === field)?.entries.find((v) => v.selected)?.value || '',
    ]),
  );

  const nonEmptyResponseFacets = response.filter((f) => !!f.entries.length);

  const hasSelection = Object.keys(selection).some((field) => !!selection[field] || !!inputValues[field]);
  const hasRequiredSelection = !!requiredFields?.every(
    (field) => !!nonEmptyResponseFacets.find((f) => f.field === field && f.entries.some((v) => v.selected)),
  );
  const hasFullSelection =
    nonEmptyResponseFacets.length > 0 &&
    nonEmptyResponseFacets.every((f) => f.entries.some((v) => v.selected));

  const disableClear = Object.keys(selection).filter((field) => !!selection[field]).length === 0;

  const submit = useCallback(() => {
    const redirectPathname = redirectUrl ?? uiConfig.searchPageUrl;
    const requestSelection = fields
      .map((field) => {
        const selectedEntry = response.find((f) => f.field === field)?.entries.find((v) => v.selected);
        return selectedEntry && { field, term: selectedEntry.term };
      })
      .filter(Boolean) as FacetRequest[];

    if (useAutonomousSearch && window.location.pathname !== redirectPathname) {
      const { href: redirectUrl } = getUriFromRequest(
        { selection: requestSelection },
        { pathname: redirectPathname },
      );
      dispatch(assignLocation(redirectUrl));
    } else {
      // case of using RequestPanel as a VehicleWidget in combination with regular facets
      const requestFitmentSelection = requestSelection.filter((facet) => fitmentFields.includes(facet.field));
      const requestFitmentSelectionFields = requestFitmentSelection.map((facet) => facet.field);

      if (fitmentBaseFields.every((field) => requestFitmentSelectionFields.includes(field))) {
        const vehicle = new Vehicle(
          requestFitmentSelection.reduce(
            (result, facet) => ({ ...result, [facet.field]: facet.term }),
            {},
          ) as VehicleValue[],
          fitmentFields,
        );

        dispatch(
          vehicleSelected(vehicle, {
            doNotRedirectOnVehicleSelect: isAutoRedirectDisabled,
            isolatedKey: null,
          }),
        );

        fitmentSearchConfig.onVehicleSelected(vehicle.filteredFieldMap);
      }

      dispatch(replaceManyValues(requestSelection, { pathname: redirectPathname }));
    }

    onSubmit?.();

    if (!collapsed) {
      toggleCollapsed();
    }
  }, [
    collapsed,
    dispatch,
    fields,
    fitmentBaseFields,
    fitmentFields,
    isAutoRedirectDisabled,
    onSubmit,
    redirectUrl,
    response,
    toggleCollapsed,
    useAutonomousSearch,
  ]);

  useEffect(
    function autoSubmit() {
      if (isAutoRedirectDisabled || !useAutonomousSearch) {
        return;
      }

      if (hasFullSelection) {
        submit();
      }
    },
    [isAutoRedirectDisabled, hasFullSelection, submit, useAutonomousSearch],
  );

  useEffect(() => {
    const response = useAutonomousSearch ? autonomousResponse : normSearchResponse;
    setResponse(response);
    setInputValues(
      response
        .filter(({ field, entries }) => inputFields?.includes(field) && !!entries.length)
        .reduce((map, { field, entries }) => {
          map[field] = entries.find((e) => e.selected)?.value?.replace(' TO *', '') ?? '';
          return map;
        }, {}),
    );
  }, [autonomousResponse, inputFields, normSearchResponse, useAutonomousSearch]);

  if (!isLoading && !response.length) {
    return null;
  }

  const stubSelects = createSelectRepeater(
    [...(selectFields || [])].map((field) => ({
      field,
      entries: [{ value: field, term: '', selected: false }],
      hideNullOption: true,
      title: field,
      key: field,
      useNativeDropdown,
      onChange: () => {
        /* stub */
      },
    })),
  );

  const actualSelects = createSelectRepeater(
    response
      .filter(({ field }) => [...(selectFields || [])].includes(field))
      .map(({ field, title, entries }) => ({
        field,
        title: title || field,
        useNativeDropdown,
        entries,
        onChange: (value: string) => onValueSelected(field, value, true),
        loading: isLoading,
        key: field,
        disabled: !entries?.length,
      })),
  );

  const stubInputs = inputFields?.map((field) => (template) => {
    const facetInput = (templ) => {
      const templProps = templ()?.props;

      const props = {
        disabled: true,
        placeholder: templProps?.placeholder ?? `Enter ${field}`,
        onChange: () => {
          /* stub */
        },
        onBlur: () => {
          /* stub */
        },
      };

      return <input {...props} />;
    };

    return template.call({ facetInput });
  }) as RepeaterFunctionInvoker<InputsRepeater>;

  const actualInputs = response
    .filter((facet) => inputFields?.includes(facet.field))
    .map(({ field, title }) => (template) => {
      const facetInput = (templ) => {
        const templProps = templ()?.props;

        const props = {
          autoComplete: 'off',
          disabled: templProps?.disabled,
          key: `input-${field}`,
          placeholder: templProps?.placeholder ?? `Enter ${title}`,
          type: 'number',
          value: inputValues[field] ?? '',
          onChange: ({ target: { value } }) => {
            setInputValues({ ...inputValues, [field]: value });
          },
          onBlur: ({ target: { value } }) => {
            if (value) {
              onValueSelected(field, `${value} TO *`, false);
            } else if (!useAutonomousSearch) {
              dispatch(discardField(field, { mayDiscardValue: true }));
            }
          },
        };

        return <input {...props} />;
      };

      return template.call({ facetInput });
    }) as RepeaterFunctionInvoker<InputsRepeater>;

  const component = template.call({
    selects: isLoading ? stubSelects : actualSelects,
    inputs: isLoading ? stubInputs : actualInputs,
    collapsed,
    clear,
    disableClear,
    hasSelection,
    hasRequiredSelection,
    hasFullSelection,
    selection,
    submit,
    toggleCollapsed,
  });
  return <SelectContextProvider>{cloneSafe(component, rootRef)}</SelectContextProvider>;
}
