import { SearchPage } from 'Components'
import TemplateMainContent from 'Stores/too-fast-autoparts/Templates/MainContent.rt'
import { FacetPanel } from 'Components'
import TemplateFacetPanelContainer from 'Stores/_default-store/Templates/FacetPanelContainer.rt'
import { SearchHeader } from 'Components'
import { SearchResult } from 'Components'
import { SearchBox } from 'Components'
import TemplateSearchBox from 'Stores/_default-store/Templates/SearchBox.rt'
import { Garage } from 'Components'
import TemplatefitmentSearchgarage from 'Stores/_default-store/Templates/fitmentSearch/garage.rt'
import { VehicleWidget } from 'Components'
import TemplatefitmentSearchhomeVehicleWidget from 'Stores/_default-store/Templates/fitmentSearch/homeVehicleWidget.rt'
import { RequestPanel } from 'Components'
import TemplateHomeWheelTireBar from 'Stores/too-fast-autoparts/Templates/HomeWheelTireBar.rt'
import TemplatefitmentSearchvehicleLabelContainer from 'Stores/too-fast-autoparts/Templates/fitmentSearch/vehicleLabelContainer.rt'
import TemplatefitmentSearchverifyFitment from 'Stores/_default-store/Templates/fitmentSearch/verifyFitment.rt'
import { FitmentTable } from 'Components'
import TemplatefitmentSearchfitmentTableTab from 'Stores/too-fast-autoparts/Templates/fitmentSearch/fitmentTableTab.rt'
import TemplatefitmentSearchfitmentTable from 'Stores/_default-store/Templates/fitmentSearch/fitmentTable.rt'
import { FacetTiles } from 'Components'
import TemplatefacetTileshierarchicalContainer from 'Stores/_default-store/Templates/facetTiles/hierarchicalContainer.rt'
import TemplatefacetTilesalphabeticalContainer from 'Stores/_default-store/Templates/facetTiles/alphabeticalContainer.rt'
import TemplatefacetTilessubtitles from 'Stores/_default-store/Templates/facetTiles/subtitles.rt'
import { RelatedItems } from 'Components'
import TemplateproductrelatedItems from 'Stores/_default-store/Templates/product/relatedItems.rt'
import { FacetDialog } from 'Components'
import TemplateFacetDialog from 'Stores/_default-store/Templates/FacetDialog.rt'
import { SearchBoxDialog } from 'Components'
import TemplateSearchBoxDialog from 'Stores/_default-store/Templates/SearchBoxDialog.rt'
import { ContextDialog } from 'Components'
import TemplatefitmentSearchdialog from 'Stores/_default-store/Templates/fitmentSearch/dialog.rt'

export const compMap = {
  SearchPage,
FacetPanel,
SearchHeader,
SearchResult,
SearchBox,
Garage,
VehicleWidget,
RequestPanel,
FitmentTable,
FacetTiles,
RelatedItems,
FacetDialog,
SearchBoxDialog,
ContextDialog
} 
export const tplMap = {
  'MainContent': TemplateMainContent,
'FacetPanelContainer': TemplateFacetPanelContainer,
'SearchBox': TemplateSearchBox,
'fitmentSearch/garage': TemplatefitmentSearchgarage,
'fitmentSearch/homeVehicleWidget': TemplatefitmentSearchhomeVehicleWidget,
'HomeWheelTireBar': TemplateHomeWheelTireBar,
'fitmentSearch/vehicleLabelContainer': TemplatefitmentSearchvehicleLabelContainer,
'fitmentSearch/verifyFitment': TemplatefitmentSearchverifyFitment,
'fitmentSearch/fitmentTableTab': TemplatefitmentSearchfitmentTableTab,
'fitmentSearch/fitmentTable': TemplatefitmentSearchfitmentTable,
'facetTiles/hierarchicalContainer': TemplatefacetTileshierarchicalContainer,
'facetTiles/alphabeticalContainer': TemplatefacetTilesalphabeticalContainer,
'facetTiles/subtitles': TemplatefacetTilessubtitles,
'product/relatedItems': TemplateproductrelatedItems,
'FacetDialog': TemplateFacetDialog,
'SearchBoxDialog': TemplateSearchBoxDialog,
'fitmentSearch/dialog': TemplatefitmentSearchdialog
};